import React from "react"
import PropTypes from "prop-types"

const TermsAndCondition = props => {
  let data = props.data

  if (props.mobileSizeWindow) {
    return (
      <div>
        <div className="parent_container_mobile">
          {data.nodes.map(item => {
            if (item.path.alias == "/terms/mobile") {
              return (
                <div
                  className="terms_mobile_body"
                  dangerouslySetInnerHTML={{
                    __html: item.field_your_terms_conditions.processed,
                  }}
                />
              )
            }
          })}
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="parent_container_desktop">
          {data.nodes.map(item => {
            if (item.path.alias == "/terms/desktop") {
              return (
                <div
                  className="terms_desktop_body"
                  dangerouslySetInnerHTML={{
                    __html: item.field_your_terms_conditions.processed,
                  }}
                />
              )
            }
          })}
        </div>
      </div>
    )
  }
}

class TermsDesktop extends React.Component {
  constructor() {
    super()
    this.state = {
      isMobile: null,
    }
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  updateDimensions() {
    if (window && window.matchMedia) {
      this.setState({
        isMobile: window.matchMedia("(max-width: 767px)").matches,
      })
    }
  }

  componentWillUnmount() {
    if (window && window.matchMedia) {
      window.removeEventListener("resize", this.updateDimensions)
    }
  }

  componentDidMount() {
    if (window && window.matchMedia) {
      this.updateDimensions()
      window.addEventListener("resize", this.updateDimensions)
    }
  }

  propTypes = {
    data: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div>
        <TermsAndCondition
          data={this.props.data}
          mobileSizeWindow={this.state.isMobile}
        />
      </div>
    )
  }
}

export default TermsDesktop
