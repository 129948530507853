import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsDesktop from "../components/termsConditions"
import PropTypes from "prop-types"

let propTypes = {
  data: PropTypes.string.isRequired,
}

export default function TermsPage(props){
  return (
    <Layout>
      <SEO
        title="Terms and Conditions - Tata Studi"
        description="Read about the Terms & conditions of Tata Studi with 
      respect to registration with Us, the use of the Application, Website, 
      Services, and products carefully before using the Application, 
      Website, Services or products."
        lang="en"
      />
      <div className="pageBannerHeading">
        <h1 className="displayNone">Terms and Conditions</h1>
        <h2>Terms & Conditions</h2>
      </div>
      <TermsDesktop data={props.data.termsData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    termsData: allNodeTermsConditions {
      nodes {
        title
        path {
          alias
        }
        field_your_terms_conditions {
          processed
        }
      }
    }
  }
`
